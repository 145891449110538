<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 16L21.489 16.5687C21.7614 16.3344 21.8294 15.9399 21.6512 15.6279L21 16ZM2.00003 16L1.34885 15.6279C1.17059 15.9399 1.23861 16.3344 1.51103 16.5687L2.00003 16ZM4.00003 12.5L4.65121 12.8721L4.75003 12.6992V12.5H4.00003ZM11.5 19.75C15.5514 19.75 19.2088 18.5294 21.489 16.5687L20.511 15.4313C18.5683 17.1018 15.2895 18.25 11.5 18.25V19.75ZM1.51103 16.5687C3.79128 18.5294 7.44865 19.75 11.5 19.75V18.25C7.71056 18.25 4.43168 17.1018 2.48903 15.4313L1.51103 16.5687ZM3.25003 9.5V12.5H4.75003V9.5H3.25003ZM3.34885 12.1279L1.34885 15.6279L2.65121 16.3721L4.65121 12.8721L3.34885 12.1279ZM11.5 1.25C6.94368 1.25 3.25003 4.94365 3.25003 9.5H4.75003C4.75003 5.77208 7.77211 2.75 11.5 2.75V1.25ZM14.2499 1.71963C13.3888 1.41528 12.4629 1.25 11.5 1.25V2.75C12.2903 2.75 13.0473 2.88549 13.7501 3.13389L14.2499 1.71963ZM21.6512 15.6279L20.2226 13.1279L18.9202 13.8721L20.3488 16.3721L21.6512 15.6279Z"
      fill="#344054"
    />
    <path
      :style="iconStyle"
      d="M15.5 18C15.5 20.2091 13.7091 22 11.5 22C9.29086 22 7.5 20.2091 7.5 18"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      :style="iconStyle"
      d="M14 7.5C14 5.567 15.567 4 17.5 4C19.433 4 21 5.567 21 7.5C21 9.433 19.433 11 17.5 11C15.567 11 14 9.433 14 7.5Z"
      fill="#F79009"
      stroke="#F79009"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '24',
    },
    width: {
      type: String,
      default: '24',
    },
    color: {
      type: String,
      default: 'icon-color-grey',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
